import React from "react";
import { convertToLocalDate } from "../../../../Utils/Common/utils";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (editScreen) => {
  const dataColumns = [
    {
      field: "row_no",
      title: "Row No",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "uc_ossi_test_case_id",
      title: "Test Case Id",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.uc_ossi_test_case_id}
        </td>
      ),
    },
    {
      field: "tenant_name",
      title: "Tenant",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 150,
    },
    {
      field: "uc_ossi_run_id",
      title: "Run ID",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_prod_id",
      title: "Prod ID",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },

    {
      field: "uc_ossi_app_test_id",
      title: "Test ID",
      editable: false,
      show: true,
      width: "250px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_run_num",
      title: "Run Num",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_actual_result",
      title: "Result",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "start_dt",
      title: "Start Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.start_dt)}</td>,
    },
    {
      field: "end_dt",
      title: "End Date",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.end_dt)}</td>,
    },
    {
      field: "uc_ossi_exec_ms",
      title: "Exec Milliseconds",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_status",
      title: "Status",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_message",
      title: "Message",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_cmnt",
      title: "Comment",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_valdt_status",
      title: "Valdt Status",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_valdt_message",
      title: "Valdt Message",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
    {
      field: "uc_ossi_test_case_valdt_cmnt",
      title: "Valdt Comment",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_test_grp_id",
      title: "Platform",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_cmd_result",
      title: "Command Result",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_pre_exec_cmd_result",
      title: "Pre Execution Command Result",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_post_exec_cmd_result",
      title: "Post Execution Command Result",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_cmd_flg",
      title: "Command Flag?",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_pre_exec_cmd_flg",
      title: "Pre Execution Command Flag?",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
    {
      field: "uc_ossi_post_exec_cmd_flg",
      title: "Post Execution Command Flag?",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: true,
    },
  ];

  return dataColumns;
};
export default DataColumns;
